@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Regular.ttf');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Medium.ttf');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Bold.ttf');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Sentient';
  src: url('./assets/fonts/Sentient-Regular.ttf');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Sentient';
  src: url('./assets/fonts/Sentient-Italic.ttf');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Sentient';
  src: url('./assets/fonts/Sentient-Medium.ttf');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Sentient';
  src: url('./assets/fonts/Sentient-MediumItalic.ttf');
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'Sentient';
  src: url('./assets/fonts/Sentient-Bold.ttf');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Sentient';
  src: url('./assets/fonts/Sentient-BoldItalic.ttf');
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('./assets/fonts/EBGaramond-Italic.ttf');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('./assets/fonts/EBGaramond-Regular.ttf');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('./assets/fonts/EBGaramond-Medium.ttf');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('./assets/fonts/EBGaramond-MediumItalic.ttf');
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('./assets/fonts/EBGaramond-SemiBold.ttf');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('./assets/fonts/EBGaramond-SemiBoldItalic.ttf');
  font-style: italic;
  font-weight: 600;
}

body {
  font-family: 'Inter', sans-serif !important;
}

h1,
h2,
h3,
h5,
h6 {
  font-family: 'EB Garamond', serif !important;
}

h4 {
  font-family: 'EB Garamond', serif !important;
  font-style: normal !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.book-progress {
  height: 6px;
  border-radius: 12px;
}

.footer-logo {
  justify-content: flex-start;

  @media (max-width: 576px) {
    justify-content: center;
  }
}

.footer-links {
  justify-content: flex-end;

  @media (max-width: 576px) {
    justify-content: center;
  }
}

.bookHTML {
  & h4 {
    font-family: serif;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    font-style: italic;
    line-height: 40px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 40px;
    margin-bottom: 70px;
  }

  & p {
    font-family: sans-serif;
    font-size: 20px;
    line-height: 32px;
    font-weight: 300;
    margin-bottom: 20px;
  }
  & p:first-letter {
    margin-left: 15px;
  }
}

.progress-container {
  margin-top: 16px;
  background-color: white;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;

  @media (max-width: 768px) {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.next-question {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 0px;

  @media (max-width: 991px) {
    font-size: 20px;
  }
}

.main-activity-container {
  padding-top: 60px;
  padding-bottom: 20px;

  @media (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.next-question-timer-container {
  padding-left: 5% !important;

  @media (max-width: 768px) {
    padding-left: 16px !important;
    margin-bottom: 40px;
  }
}

.current-question {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 0px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.all-answers-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;

  @media (max-width: 991px) {
    justify-content: center;
  }
}

.multiline-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #c1c7de;
  margin-top: 20px;
  margin-bottom: 20px;
}

.button-update {
  border-radius: 20px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 32px !important;
  padding-right: 32px !important;
  font-weight: 600 !important;
}

.hidden-from-review {
  display: none;
}

.accordion-button:not(.collapsed) {
  background-color: white !important;
}

.content-editable {
  border-style: solid;
  border-color: blue;

  & :focus-visible {
    border-color: blue;
  }
}

.book-chapter p {
  font-family: 'EB Garamond';
  text-align: justify;
}

.book-chapter figcaption {
  font-family: 'EB Garamond';
}

.layout {
  background-color: #fff3cc;
  display: flex;
  min-height: 100vh;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.main-container {
  padding: 0 40px 40px 40px;
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
}

.main-navigation {
  border-right: 1px solid #e8daab;
}

.main-navbar {
  position: sticky !important;
  top: 0 !important;
  padding: 24px 0 !important;
  display: flex;
  flex-direction: column;
}

.navbar-logout {
  left: 16px;
  bottom: 16px;
  display: flex;
  align-items: center;
  position: fixed;
  gap: 12px;
  width: 240px;
  overflow-x: hidden;
}

.users-table td {
  vertical-align: middle;
}

.button-transparent {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #000000;
  padding: 8px 12px;
  color: #000;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  transition: all 0.2s ease;
}

.button-transparent:hover {
  background-color: #f1f1f1;
}

.button-black {
  border-radius: 4px;
  background-color: #000;
  padding: 8px 12px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  transition: all 0.2s ease;
}

.button-black:hover {
  background-color: #414141;
}

.login-screen-container {
  gap: 16px;
  display: flex;
  flex-wrap: wrap;
}

.login-screen-card {
  display: flex;
  border-radius: 4px;
  padding: 24px 120px;
  background-color: #fff;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}

@media (max-width: 991px) {
  .main-navbar {
    position: relative !important;
    top: 0 !important;
    flex-direction: row;
    margin: 12px;
    padding: 12px 0 24px !important;
    border-bottom: 1px solid #e8daab;
  }

  .navbar-logout {
    position: static;
    margin-top: 24px;
  }
}

@media (max-width: 480px) {
  .login-screen-container {
    flex-direction: column;
    flex-wrap: initial;
  }

  .login-screen-card {
    padding: 24px;
  }
}
